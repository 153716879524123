<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img src="@/assets/images/profile-bg.jpg" class="profile-wid-img" alt=""/>
        <div class="overlay-content">
          <div class="text-end p-3">
            <div class="p-0 ms-auto rounded-circle profile-photo-edit d-none">
              <input id="profile-foreground-img-file-input" type="file" class="profile-foreground-img-file-input"/>
              <label for="profile-foreground-img-file-input" class="profile-photo-edit btn btn-light">
                <i class="ri-image-edit-line align-bottom me-1"></i> Alterar capa </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-3">
        <div class="card mt-n5">
          <div class="card-body p-4">
            <div class="text-center">
              <div class="
                  profile-user
                  position-relative
                  d-inline-block
                  mx-auto
                  mb-4
                ">

                <Avatar :Photo="usuario.Photo" />
                {{usuario.Photo}}

              </div>
              <h5 class="fs-16 mb-1">{{usuario.Nome}}</h5>
              <p class="text-muted mb-0">{{usuario.perfil_descricao}}</p>
              <p class="text-info mb-0">{{usuario.email}}</p>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
          <div class="card-body p-4">

            <b-tabs content-class="mt-3" class="nav-tabs-custom">
              <b-tab title="Minha conta"  active>
                <form action="javascript:void(0);">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="usuario_nome" class="form-label">
                          Nome completo
                        </label>
                        <input type="text"
                               class="form-control"
                               id="usuario_nome"
                               placeholder="Digite seu nome completo"
                               v-model="usuario.Nome"/>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="usuario_nomeprofissional" class="form-label">Nome profissional</label>
                        <input type="text"
                               class="form-control"
                               id="usuario_nomeprofissional"
                               placeholder="Nome profissional"
                               v-model="usuario.NomeProfissional"/>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-sm-4">
                      <div class="mb-3">
                        <label for="usuario_telefone" class="form-label">Telefone</label>
                        <input type="text" class="form-control" id="usuario_telefone"
                               placeholder="Seu telefone" v-model="usuario.Celular"/>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <label for="usuario_sexo"
                             class="form-control-label">
                        Sexo
                      </label>
                      <select class="form-control"
                              id="usuario_sexo"
                              v-model="usuario.Sexo"
                      >
                        <option>M</option>
                        <option>F</option>
                      </select>
                    </div>

                    <div class="col-sm-4">
                      <div class="mb-3">
                        <label for="usuario_DataNascimento" class="form-label">Data de nascimento</label>

                        <flat-pickr v-model="usuario.DataNascimento"
                                    data-date-format="d/m/Y"
                                    :config="this.config"
                                    id="usuario_DataNascimento"
                                    class="form-control"></flat-pickr>

                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="mb-3">
                        <label for="usuario_cpf" class="form-label">CPF</label>
                        <input type="text" class="form-control"
                               minlength="11"
                               v-model="usuario.CPF"
                               maxlength="11" id="usuario_cpf"
                               placeholder="CPF" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-5">
                      <div class="mb-3">
                        <label for="usuario_email" class="form-label">Email</label>
                        <input type="email" class="form-control" id="usuario_email" placeholder="Informe seu e-mail"
                               v-model="usuario.email"/>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3">
                      <div class="mb-3">
                        <label for="usuario_cep" class="form-label">CEP</label>
                        <input type="text" class="form-control"
                               minlength="5"
                               v-model="usuario.CEP"
                               maxlength="6" id="usuario_cep"
                               placeholder="CEP" />
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="text-end">
                        <button type="submit" class="btn btn-success" @click="updatedata">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--end row-->
                </form>
              </b-tab>
              <b-tab title="Trocar senha">
                <form action="javascript:void(0);">
                  <div class="row g-2">
                    <div class="col-lg-4">
                      <div>
                        <label for="oldpasswordInput" class="form-label">Senha atual*</label>
                        <input type="password" class="form-control" id="oldpasswordInput"
                               placeholder="Senha atual"/>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="newpasswordInput" class="form-label">Nova senha*</label>
                        <input type="password" class="form-control" id="newpasswordInput"
                               placeholder="Nova senha"/>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="confirmpasswordInput" class="form-label">Confirme a senha*</label>
                        <input type="password" class="form-control" id="confirmpasswordInput"
                               placeholder="Confirme a senha"/>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <a href="javascript:void(0);" class="link-primary text-decoration-underline">
                          Equeçeu a senha?
                        </a>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="text-end">
                        <button type="submit" class="btn btn-success" @click="changepass">
                          Trocar senha
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </b-tab>
            </b-tabs>

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
<script>
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js"
import "flatpickr/dist/flatpickr.css";
import axios from 'axios';
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import Avatar from "@/components/Avatar";


export default {
  page: {
    title: "Configurações",
    meta: [{
      name: "description",
      content: appConfig.description
    }],
  },
  data() {
    return {
      title: "Configurações",
      items: [{
        text: "Velzon",
        href: "/",
      },
        {
          text: "Configurações",
          active: true,
        },
      ],
      value: ['javascript'],
      date: null,
      usuario: {
        Nome:'',
        email:'',
        NomeProfissional:'',
        Celular:'',
        DataNascimento:'',
        Photo:'',
        CEP:'',
        CPF:'',
        perfil_descricao:'',
      },
      config: {
        altFormat: "j F Y",
        dateFormat: 'Y-m-d',
        altInput: true,
        locale: Portuguese,
      }
    };
  },
  components: {
    Layout,
    flatPickr,
    Avatar

  },
  methods: {
    changepass() {
      var userid = localStorage.getItem('userid');
      var data = {
        password: document.getElementById('oldpasswordInput').value,
        new_password: document.getElementById('newpasswordInput').value,
        confirm_password: document.getElementById('confirmpasswordInput').value
      }
      axios.post(process.env.VUE_APP_API+'/v1/alterarsenha/' + userid, data).then((data) => {
        console.log(data);
      }).catch((e) => {
        console.log(e)
      })
    },
    updatedata() {
      try {
        var userid = localStorage.getItem('userid');
        var data = {
          Nome: document.getElementById('usuario_nome').value,
          NomeProfissional: document.getElementById('usuario_nomeprofissional').value,
          CPF: document.getElementById('usuario_cpf').value,
          Telefone: document.getElementById('usuario_telefone').value,
          email: document.getElementById('usuario_email').value,
          Sexo: document.getElementById('usuario_sexo').value,
          DataNascimento: document.getElementById('usuario_DataNascimento').value,
          CEP: document.getElementById('usuario_cep').value,
        }
        axios.post(process.env.VUE_APP_API+'/v1/salvarusuario/' + userid, data).then((data) => {
          console.log(data);
        }).catch((e) => {
          console.log(e)
        })
      }catch (e){
        console.log(e);
      }
    },
  },
  mounted() {

    const usuario = JSON.parse(localStorage.getItem('userdata'));
    this.usuario.Nome = usuario.Nome;
    this.usuario.email = usuario.email;
    this.usuario.NomeProfissional = usuario.NomeProfissional;
    this.usuario.Celular = usuario.Celular;
    this.usuario.DataNascimento = usuario.DataNascimento;
    this.usuario.Sexo = usuario.Sexo;
    this.usuario.CEP = usuario.CEP;
    this.usuario.CPF = usuario.CPF;
    this.usuario.perfil_descricao = usuario.perfil_descricao;


  },

};

</script>