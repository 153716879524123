<template>
  <div class="wrapper">
    <div>
      <img  :src="PhotoLocal"
           alt="Photo"
           title="Photo"
           class="avatar rounded-circle
                    avatar-xl
                    img-thumbnail
                    user-profile-image" />


      <button type="button"
              class="btn btn-primary btn-sm"
              @click="changeCropperVisibility">
        Mudar foto
      </button>


      <button v-if="Photo"
              type="button"
              class="btn btn-danger btn-sm"
              @click="removePhoto">
        Remover
      </button>
    </div>

    <p style="color: red">{{ message }}</p>

    <avatar-cropper
        v-model="showCropper"
        :cropper-options="{
        aspectRatio: 1,
        autoCropArea: 1,
        viewMode: 1,
        movable: false,
        zoomable: true,
      }"
        :mimes="'image/png, image/jpg, image/jpeg'"
        :upload-handler="handleUploaded"
        @changed="onChanged"
        :output-options="{ width: 150, height: 150 }"
        :labels="{ submit: 'Enviar', cancel: 'Cancelar' }"
    />
  </div>
</template>

<style lang="scss" scoped>
* {
  text-align: center;
}
.wrapper {
 margin-top: -80px;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: block;
  margin: 20px auto;
}

.empty-avatar {
  display: grid;
  place-items: center;
  margin: 20px auto;
  width: 150px;
  height: 150px;
  background-color: #f47c20;
  border-radius: 50%;

  & > p {
    margin: 0;
    font-size: 3rem;
    color: white;
  }
}

::v-deep(.cropper-view-box) {
  border-radius: 50%;
  outline: 1px solid #ff5722;
  outline-color: #ff5722;
}

::v-deep(.cropper-line) {
  background-color: #ff5722;
}

::v-deep(.cropper-point) {
  background-color: #ff5722;
}

::v-deep(.avatar-cropper .avatar-cropper-mark) {
  background: rgba(107, 59, 59, 0.5);
}

::v-deep(.cropper-modal) {
  opacity: 0.1;
}

::v-deep(.avatar-cropper .avatar-cropper-container) {
  border-radius: 16px;
  overflow: hidden;
}

::v-deep(.avatar-cropper
    .avatar-cropper-container
    .avatar-cropper-footer
    .avatar-cropper-btn:hover) {
  background-color: #f47c20;
}
</style>

<script>
import { ref } from "vue";
import AvatarCropper from "vue-avatar-cropper";
import http from 'axios';
import Swal from "sweetalert2";

export default {
  name: "Avatar",
  components: { AvatarCropper },
  data() {
    return {
      PhotoLocal: localStorage.getItem('user-Photo'),
      URL : process.env.VUE_APP_API
    };
  },
  emits: ["cancel"],
  setup() {

    const maxFileSize = 4;
    const showCropper = ref(true);
    const message = ref("");

    const user = ref({
      id: 1,

    });

    const handleUploaded = (data) => {
      try {


        const base64str = data.url.substring(data.url.indexOf(",") + 1);
        const decoded = atob(base64str);
        const decodedFileSize = decoded.length / 1024 ** 2;

        if (decodedFileSize > maxFileSize) {
          message.value =
              "Foto é muito grande, favor informar tamanho inferior 4 mb";

          setTimeout(() => {
            message.value = "";
          }, 2500);
        } else {

          user.value.avatar = data.getCroppedCanvas().toDataURL("image/png");

          try {

              http.post(process.env.VUE_APP_API +"/v1/salvarphotousuario", {photo:user.value.avatar})
                .then((response) => {

                  try {


                    localStorage.setItem('user-Photo',response.data.Photo);
                    location.reload();
                  } catch (e) {
                    console.log(e);
                  }

                }).catch(error => {

              this.errors = error.response.data;
              Swal.fire({
                title: 'Atenção!',
                text: error.response.data.error,
                icon: 'error',
                confirmButtonText: 'Cool'
              });

            });


          } catch (e) {
            if (e.response?.data) {
              this.errors = e.response.data;
            }

          }

        }
      }catch (e){
        console.log(e);
      }
    };

    const changeCropperVisibility = () => {
      showCropper.value = true;
    };

    const onChanged = (event) => {
      console.log(event.file.size);
    };

    const removePhoto = () => {
      user.value.avatar = "";
      message.value = "";
    };


    return {
      showCropper,
      message,
      user,
      handleUploaded,
      changeCropperVisibility,
      removePhoto,
      onChanged,
    };
  },
  methods:{
    debug: ()=> console.log('teste')
  },
  computed :{
    console: () => console,
    window: () => window,
  },
  mounted() {
    console.log(this.Photo);
  },
  props:[
      'Photo'
  ],
  updated() {
    console.log('updated!')
  },
  beforeCreate() {
    console.log('Value of val is: ' + this.val)
  },
  created() {
    console.log('Value of val is: ' + this.val)
  },
  beforeUpdate() {
    console.log('beforeUpdate() val: ' + this.val)
  },
  unmounted() {
    /* Options API */
    console.log('unmounted')
  },
};
</script>

